import classNames from 'classnames';

import { Link } from '../link';

export type TOCLink = {
  id: string;
  title: string;
};

export const TableOfContent: React.FC<{
  nestedHeadings: TOCLink[];
  currentLink: string;
}> = ({ nestedHeadings, currentLink }) => {
  if (nestedHeadings.length === 0) return <></>;

  const headingClassNames = (url: string) => {
    return classNames(
      'dark:text-grey-20 table-of-content-heading table-of-content-indicatorbar',
      {
        'table-of-content-indicator': url.indexOf('#') === -1,
      },
    );
  };

  return (
    <nav
      aria-label="Table of contents"
      className="hidden overflow-y-auto sticky top-0 col-span-2 col-start-12 pt-40 pb-20 -mr-8 ml-4 h-screen lg:block"
      id="TableOfContents"
    >
      <h4 className={headingClassNames(currentLink)}>Quick Nav</h4>
      <ul>
        {nestedHeadings.map(({ id, title }) => {
          const urlHash = currentLink.split('#')[1];
          const isMatchingLink = urlHash === id;

          const linkClassNames = classNames(
            'dark:text-grey-40 dark:hover:text-grey-40 dark:focus:text-grey-40 table-of-content-link',
            {
              'table-of-content-link-active': isMatchingLink,
            },
          );

          const linkContainerClassNames = classNames(
            'table-of-content-indicatorbar',
            {
              'table-of-content-indicator': isMatchingLink,
            },
          );

          return (
            <li className={linkContainerClassNames} key={id}>
              <Link
                aria-label={`Click to quickly navigate to ${title}`}
                className={linkClassNames}
                href={`#${id}`}
              >
                {title}
              </Link>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};
