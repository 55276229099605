import NextLink, { LinkProps } from 'next/link';
import { AnchorHTMLAttributes, ReactNode } from 'react';

type LinkPropsType = {
  href: LinkProps['href'];
  attrs?: AnchorHTMLAttributes<HTMLAnchorElement>;
  isOpenedInNewTab?: boolean;
  children: ReactNode;
  className?: string;
};

export const Link = (props: LinkPropsType) => {
  const { children, className, href, isOpenedInNewTab, attrs, ...rest } = props;

  const additionalAttrs: AnchorHTMLAttributes<HTMLAnchorElement> = { ...attrs };

  if (isOpenedInNewTab) {
    additionalAttrs.target = '_blank';
    additionalAttrs.rel = 'noopener noreferrer';
  }

  return (
    <NextLink href={href}>
      <a
        {...additionalAttrs}
        className={className}
        suppressHydrationWarning
        {...rest}
      >
        {children}
      </a>
    </NextLink>
  );
};
