/**
 * Separate routing handling to simplified TDD test for table of content
 */
import { useRouter } from 'next/router';
import { FC, ReactNode, useEffect, useState } from 'react';

import { useCurrentLink } from 'lib/customHooks';

import { TableOfContent, TOCLink } from './table-of-content';

/**
 * Filtering blocks title elements to return array of
 * block title always display in the following HTML format:
 * @example
 * <h2 class="h2" id="block-id"><a href="" /><span>Block Name</span></h2>
 */
const getNestedHeadings = (
  headingElements: HTMLHeadingElement[],
): TOCLink[] => {
  const nestedHeadings: TOCLink[] = [];
  const isAnchorHeading = (heading: HTMLElement): boolean => {
    return (
      heading.nodeName === 'H2' &&
      Array.from(heading.children).findIndex((node) => node.nodeName === 'A') >=
        0
    );
  };

  headingElements.forEach((heading) => {
    if (isAnchorHeading(heading)) {
      const { id, innerText: title } = heading;

      if (id === '' || title === '') return;

      nestedHeadings.push({ id, title });
    }
  });

  return nestedHeadings;
};

/**
 * Custom Hooks to find all h2 headings on the page
 * auto update when content changes
 */
export const useHeadingsData = (childrenNode: ReactNode) => {
  const [nestedHeadings, setNestedHeadings] = useState<TOCLink[]>([]);

  useEffect(() => {
    const headingElements = Array.from(document.querySelectorAll('h2'));

    const newNestedHeadings = getNestedHeadings(headingElements);
    setNestedHeadings(newNestedHeadings);
  }, [childrenNode]);

  return nestedHeadings;
};

export const TOCContainer: FC<{ childrenNode: ReactNode }> = ({
  childrenNode,
}) => {
  const router = useRouter();
  const { currentLink } = useCurrentLink(router);
  const nestedHeadings = useHeadingsData(childrenNode);

  /**
   * tempt solution to handle when using direct history API push state
   * @TODO remove this setCurrentURL state when the anchor jumping using next router is fixed
   */
  const [currentURL, setCurrentURL] = useState(currentLink);
  useEffect(() => {
    setCurrentURL(currentLink);
  }, [currentLink]);

  /**
   * manually register hash change into next routing
   * when user typing hash ONLY into url tab
   */
  function handleHashChanged(event: Event | HashChangeEvent) {
    const newURL = new URL((event as HashChangeEvent).newURL);
    router.push({
      hash: newURL.hash,
    });
  }

  // Considering we divide viewport into 2 zones of each 50vh
  // This returns true if an element is in the first zone (ie, appear in viewport, near the top)
  function isInViewportTopHalf(boundingRect: DOMRect) {
    return boundingRect.top > 0 && boundingRect.bottom < window.innerHeight / 2;
  }

  function getVisibleBoundingClientRect(element: HTMLElement): DOMRect {
    const rect = element.getBoundingClientRect();
    const hasNoHeight = rect.top === rect.bottom;
    if (hasNoHeight) {
      return getVisibleBoundingClientRect(element.parentNode as HTMLElement);
    }

    return rect;
  }

  /**
   * get upcoming active heading
   */
  function getActiveHeading() {
    const headings = [...document.querySelectorAll('main h2')];
    const navigationHeader = document.querySelector('header');
    const offsetTop = (navigationHeader as HTMLElement).getBoundingClientRect()
      .height;

    const nextVisibleHeading = headings.find((heading) => {
      const boundingRect = getVisibleBoundingClientRect(heading as HTMLElement);

      return boundingRect.top >= offsetTop;
    });

    if (nextVisibleHeading) {
      const boundingRect = getVisibleBoundingClientRect(
        nextVisibleHeading as HTMLElement,
      );
      // If heading is in the top half of the viewport: it is the one we consider "active"
      // (unless it's too close to the top and and soon to be scrolled outside viewport)
      if (isInViewportTopHalf(boundingRect)) {
        return nextVisibleHeading;
      }

      // If heading is in the bottom half of the viewport, or under the viewport, we consider the active anchor is the previous one
      // Returns null for the first heading
      return headings[headings.indexOf(nextVisibleHeading) - 1] ?? null;
    }

    // no heading under viewport top? (ie we are at the bottom of the page)
    // => highlight the last heading found
    return headings[headings.length - 1];
  }
  /**
   * update hash on scroll when heading id is in view port
   */
  function handleScroll() {
    const activeHeading = getActiveHeading();
    if (activeHeading && `#${activeHeading.id}` !== window.location.hash) {
      /**
       *  @TODO temporary hack to stop anchor jumping within the same page scroll
       * until this issue is fixed by NextJS https://github.com/vercel/next.js/pull/27195
       */
      window.history.pushState(
        null,
        '',
        `${window.location.pathname}#${activeHeading.id}`,
      );
      /**
       * handle this case when hash change not registered in next Router
       * @TODO remove when the hack above is removed
       */
      setCurrentURL(`${window.location.pathname}#${activeHeading.id}`);
    }
  }

  /**
   * subscribe to hashchange window event
   * because NextJS Router fails to register link changes
   * when user typing hash ONLY into url tab
   */
  useEffect(() => {
    window.addEventListener('hashchange', handleHashChanged);

    return () => {
      window.removeEventListener('hashchange', handleHashChanged);
    };
  }, []);

  useEffect(() => {
    if (nestedHeadings.length)
      window.addEventListener('scroll', handleScroll, false);

    return () => {
      window.removeEventListener('scroll', handleScroll, false);
    };
  }, [nestedHeadings]);

  return (
    <TableOfContent currentLink={currentURL} nestedHeadings={nestedHeadings} />
  );
};
