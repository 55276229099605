import classNames from 'classnames';
import React from 'react';

import CloseIcon from '../../../assets/images/close-icon.svg';
import InfoCircleIcon from '../../../assets/images/info-circle-icon.svg';
import { Link } from '../link';
import { useVersionSelect } from '../version-select/version-select.provider';

export type VersionAlertType = {
  hasSidebar: boolean;
};

export const VersionAlert: React.FC<VersionAlertType> = ({ hasSidebar }) => {
  const {
    versionState: { current, latest, showAlert },
    closeAlert,
  } = useVersionSelect();

  if (!showAlert || !current || !latest) {
    return null;
  }

  return (
    <dialog
      className={classNames(
        'fixed top-20 z-30 w-full p-0 text-blue-900 dark:text-grey-20 bg-blue-100 dark:bg-blue-800',
        { 'top-[8.625rem] lg:top-20': hasSidebar },
      )}
      open
      role="alert"
    >
      <div className="container mx-auto">
        <div className="grid grid-cols-14 grid-flow-col gap-m py-s w-full">
          <div className="flex col-span-2 justify-end items-center">
            <InfoCircleIcon height={24} width={24} />
          </div>
          <div className="flex flex-col col-span-10 text-sm">
            <div>
              This is documentation for Bakkt {current.name}, which is no longer
              actively maintained. For up-to-date documentation, see the{' '}
              <Link href={latest.path}>
                <button
                  className="underline hover:no-underline focus:no-underline"
                  onClick={closeAlert}
                  onKeyPress={closeAlert}
                  role="link"
                >
                  latest version here
                </button>
              </Link>
              .
            </div>
          </div>
          <button
            aria-label="Close the Alert. "
            className="flex col-span-2 items-center"
            id="alert-close"
            onClick={closeAlert}
          >
            <CloseIcon height={20} width={20} />
          </button>
        </div>
      </div>
    </dialog>
  );
};
