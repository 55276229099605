import classNames from 'classnames';

import LinkOutIcon from 'assets/images/linkout.svg';
import { Link } from 'components/common/link';

type SideBarLink = {
  url?: string;
  title: string;
  openInNewTab?: boolean;
  links?: SideBarLink[];
  className?: string;
  isActive?: boolean;
};

export const SideBarLinkContainer: React.FC<SideBarLink> = ({
  url = '/',
  title,
  openInNewTab = false,
  className = '', // Should be used for margin, padding, text-*, font-*, custom (e.g. accordion-item)
  isActive = false,
}) => {
  return (
    <li
      className={classNames(
        'table -mr-2.5 col-start-1 w-full rounded-sm lg:mx-0',
        className,
        {
          'bg-menu-highlight link-active text-blue-900 dark:bg-grey-700 dark:text-grey-40':
            isActive,
        },
      )}
    >
      <Link
        aria-current={isActive ? 'page' : undefined}
        aria-label={`Click to navigate to ${title}`}
        className={classNames('flex items-center w-full', {
          'justify-between ': !openInNewTab,
        })}
        href={url}
        isOpenedInNewTab={openInNewTab}
      >
        {title}
        {openInNewTab && (
          <span className="pl-1 text-grey-300 dark:text-grey-600">
            <LinkOutIcon />
          </span>
        )}
      </Link>
    </li>
  );
};
