import classNames from 'classnames';

import { Link } from 'components/common/link';

import { SuiteVersionRecord } from '../../../types';
import { DarkModeToggle } from '../dark-mode-toggle';

import { HeaderSelectDesktop } from './header-select-desktop';
import { NavItem, NavItemType } from './nav-item';

export type HeaderDesktopType = {
  headerLinks: Array<NavItemType>;
  versions: SuiteVersionRecord[];
  onVersionChange: (x: any) => void;
  versionSelected: SuiteVersionRecord;
  showVersions: boolean;
};

export const HeaderDesktop: React.FC<HeaderDesktopType> = (props) => {
  const {
    headerLinks,
    onVersionChange,
    versions,
    versionSelected,
    showVersions,
  } = props;
  const menuClassName = classNames('lg:flex lg:h-full');
  const supportLink = {
    name: 'Support',
    path: '/support',
  };

  return (
    <div className="hidden ml-32 w-full h-full lg:block">
      <nav className={menuClassName}>
        <h2 className="sr-only">Global navigation</h2>
        <ul className="flex w-full h-full">
          {headerLinks?.map((headerLink) => (
            <NavItem {...headerLink} key={headerLink.id} />
          ))}
        </ul>
        <div className="flex items-center ml-auto">
          {/* suite exists and has versions length > 0 */}
          {showVersions && (
            <div className="mr-2 h-full max-h-[2.5rem]">
              <HeaderSelectDesktop
                onChange={onVersionChange}
                options={versions}
                value={versionSelected.value}
              />
            </div>
          )}

          <div className="flex items-stretch">
            <Link
              className="flex items-center px-3 h-full"
              href={supportLink.path}
            >
              <span className="tracking-wider text-green-400 bg-green-900 hover:bg-green-900/80 cursor-pointer btn">
                {supportLink.name}
              </span>
            </Link>
          </div>
          <div className="flex items-center ml-2">
            <DarkModeToggle />
          </div>
        </div>
      </nav>
    </div>
  );
};
