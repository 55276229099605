import classNames from 'classnames';
import { HTMLProps, MouseEvent as ReactMouseEvent } from 'react';

import { Link } from 'components/common/link';

export const PRIMARY = 'primary';
export const SECONDARY = 'secondary';

export type AnchorClickEvent = (
  event: ReactMouseEvent<HTMLAnchorElement, MouseEvent>,
) => void;
export type ButtonClickEvent = (
  event: ReactMouseEvent<HTMLButtonElement, MouseEvent>,
) => void;

type Props = Omit<HTMLProps<HTMLButtonElement>, 'disabled'> & {
  href?: string;
  className?: string;
  type?: 'button' | 'submit';
  variant?: 'primary' | 'secondary';
  onClick?: AnchorClickEvent | ButtonClickEvent;
  isDisabled?: boolean;
  isLink?: boolean;
  isOpenedInNewTab?: boolean;
};

const BASE_CLASSES = [
  'align-center',
  'duration-300',
  'font-normal',
  'shrink',
  'justify-center',
  'inline-flex',
  'items-center',
  'leading-normal',
  'px-m',
  'py-xs',
  'text-base',
  'transition-colors',
  'tracking-extra-wide',
  'focus:ring-2',
  'focus:ring-green-400',
  'focus:outline-none',
  'hover:opacity-80',
  'outline-none',
  'rounded-full',
];

export const Button: React.FC<Props> = ({
  children,
  className,
  href,
  onClick,
  type = 'button',
  variant = PRIMARY,
  isDisabled,
  isLink,
  isOpenedInNewTab = false,
  ...rest
}) => {
  const typeMap = {
    [PRIMARY]: {
      base: [
        'bg-green-900',
        'text-green-400',
        'hover:bg-green-550',
        'active:bg-blue-900',
      ],
    },
    [SECONDARY]: {
      base: [
        'bg-white',
        'text-blue-900',
        'hover:bg-green-100',
        'dark:hover:bg-green-100',
        'hover:text-blue-900',
        'active:bg-blue-900',
        'active:text-green-400',
      ],
    },
  };

  const classes = classNames(className, BASE_CLASSES, typeMap[variant].base, {
    'pointer-events-none opacity-50': isDisabled,
  });

  if (isLink && href) {
    return (
      <Link
        className={classNames(classes, {
          [`btn btn-${variant}`]: type === 'button',
        })}
        href={href}
        isOpenedInNewTab={isOpenedInNewTab}
        {...rest}
      >
        {children}
      </Link>
    );
  }

  return (
    <button
      {...rest}
      className={classes}
      disabled={isDisabled}
      onClick={onClick as ButtonClickEvent}
      type={type}
    >
      {children}
    </button>
  );
};
