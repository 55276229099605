import Link from 'next/link';

import BakktLogo from 'public/bakkt-logo.inline.svg';

import { Container } from '../container';
import { FlattenSideBarLinks } from '../layout';

import { Nav } from './nav';
import { NavItemType } from './nav-item';

export type HeaderType = {
  headerLinks: Array<NavItemType>;
};

export const Header: React.FC<
  HeaderType & {
    sideBarLinks?: FlattenSideBarLinks;
  }
> = ({ headerLinks, sideBarLinks }) => {
  return (
    <header className="fixed top-0 z-40 w-full text-blue-900 dark:text-white bg-grey-20 dark:bg-blue-900 border-b border-b-grey-100 dark:border-b-grey-700 header">
      <Container>
        <div className="flex justify-between items-center h-20 lg:justify-start">
          <Link href="/">
            <a className="flex items-center text-md hover:text-green-400 cursor-pointer transition-color">
              <BakktLogo
                className="mr-3 w-24 h-8 hover:text-green-400 lg:w-18 lg:h-auto transition-color"
                role="presentation"
              />
              <span className="sr-only">Bakkt</span> Developer
            </a>
          </Link>
          <Nav headerLinks={headerLinks} sideBarLinks={sideBarLinks} />
        </div>
      </Container>
    </header>
  );
};
