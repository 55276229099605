import { NextRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';

/**
 * Custom hooks to return full URL after dynamic routing is completed
 */
export const useCurrentLink = (router: NextRouter) => {
  const [currentLink, setCurrentLink] = useState('');

  useEffect(() => {
    setCurrentLink(router.asPath);
  }, [router]);

  // this seems to be required everywhere useCurrentLink is used
  // could be '/' at end of path so remove if it exists
  const currentPath = currentLink.split('#')[0].replace(/\/$/, '');

  return { currentLink, currentPath };
};

/**
 *  Customise handling after routing change complete
 */
export const useHandleWhenRouterChangeComplete = (
  router: NextRouter,
  handleRouterChangeCompleted: () => void,
) => {
  useEffect(() => {
    router.events.on('routeChangeComplete', handleRouterChangeCompleted);

    return () => {
      router.events.off('routeChangeComplete', handleRouterChangeCompleted);
    };
  }, [router.events]);
};

/**
 * Handling body scroll lock
 * @param isBodyScrollLocked boolean -- condition to lock body scroll
 * @param overrideHandling function -- override default lockbody scroll
 */
export const useHandleLockBodyScroll = ({
  isBodyScrollLocked,
  overrideHandling,
}: {
  isBodyScrollLocked: boolean;
  overrideHandling?: () => void;
}) => {
  useEffect(() => {
    if (overrideHandling) {
      overrideHandling();

      return;
    }

    if (isBodyScrollLocked) {
      document.body.classList.remove('overflow-auto');
      document.body.classList.add('overflow-hidden');

      return;
    }
    document.body.classList.remove('overflow-hidden');
    document.body.classList.add('overflow-auto');
  }, [isBodyScrollLocked]);
};

export const useDownloadFile = (url: string) => {
  const ref = useRef<HTMLAnchorElement | null>(null);
  const [fileUrl, setFileUrl] = useState<string>();

  const handleFileDownload = () => {
    fetch(url, {
      method: 'GET',
      mode: 'cors',
    })
      .then((response) => response.blob())
      .then((blob) => {
        const source = window.URL.createObjectURL(new Blob([blob]));
        setFileUrl(source);
        ref.current?.click();
        window.URL.revokeObjectURL(source);
      });
  };

  return { handleFileDownload, ref, fileUrl };
};

export const useScrollPadding = () => {
  useEffect(() => {
    document.documentElement.classList.add('scrolling-padding-top');

    return () => {
      document.documentElement.classList.remove('scrolling-padding-top');
    };
  }, []);
};
