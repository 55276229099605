import classNames from 'classnames';
import { useEffect, useState } from 'react';

import ChevronDown from 'assets/images/chevron-down.sidebar.svg';
import { SimpleLink } from 'types/sidebar-types';

import { SideBarLinkContainer } from './sidebar-linkcontainer';

export const DesktopAccordionLabel: React.FC<{
  disabled: boolean;
  onClick: () => void;
  title: string;
  showLinks: boolean;
}> = ({ disabled, onClick, title, showLinks }) => (
  <button
    className="hidden w-full text-left lg:block accordion-button"
    disabled={disabled}
    onClick={onClick}
  >
    <span className="flex flex-row justify-between items-center text-base font-normal leading-normal text-blue-900 dark:text-grey-40 lg:pl-2">
      <span className="flex-1">{title}</span>
      <span className="flex justify-end w-[28px]">
        <ChevronDown
          className={classNames(
            'text-blue-900 dark:text-grey-40 fill-current',
            {
              'transform rotate-180': showLinks,
              hidden: disabled,
            },
          )}
        />
      </span>
    </span>
  </button>
);

const MobileAccordionLabel: React.FC<{ title: string }> = ({ title }) => (
  <p className="flex flex-row items-center px-xxs w-full text-base font-normal leading-normal text-blue-900 dark:text-grey-40 lg:hidden accordion-label">
    {title}
  </p>
);

export const SideBarAccordion: React.FC<{
  url?: string;
  title: string;
  openNewTab?: boolean;
  links?: SimpleLink[];
  className?: string;
  currentLink: string;
  isAlwaysExpanded: boolean;
}> = ({ title, links = [], className, currentLink, isAlwaysExpanded }) => {
  const [showLinks, setShowLinks] = useState(false);

  const isCurrentLink = (linkArr: SimpleLink[], url: string) => {
    return !!linkArr.find((link) => link.linkUrl === url);
  };

  useEffect(() => {
    if (isAlwaysExpanded) {
      return setShowLinks(true);
    }

    // when currentLink changes show / hide
    return setShowLinks(isCurrentLink(links, currentLink));
  }, [currentLink]);

  return (
    <li
      className={classNames(
        'table col-start-1 lg:w-full accordion w-full',
        className,
      )}
    >
      <DesktopAccordionLabel
        disabled={isAlwaysExpanded}
        onClick={() => setShowLinks(!showLinks)}
        showLinks={showLinks}
        title={title}
      />
      <MobileAccordionLabel title={title} />
      {links.length > 0 && (
        <ul className="mt-3.5">
          {links.map(({ id, linkName, linkUrl }) => (
            <SideBarLinkContainer
              className={classNames(
                'px-xxs mb-2 lg:px-2 w-full text-sm leading-7 text-grey-700 dark:text-grey-40 hover:text-blue-900 hover:bg-grey-40 dark:hover:bg-grey-700 accordion-item',
                {
                  // only desktop links are (accessibly) hidden when showLinks is false
                  'lg:absolute lg:-z-1 lg:opacity-0': !showLinks,
                },
              )}
              isActive={currentLink === linkUrl}
              key={id}
              title={linkName}
              url={linkUrl}
            />
          ))}
        </ul>
      )}
    </li>
  );
};
