import classNames from 'classnames';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import { Link } from 'components/common/link';
import {
  useCurrentLink,
  useHandleLockBodyScroll,
  useHandleWhenRouterChangeComplete,
} from 'lib/customHooks';

import { SuiteVersionRecord } from '../../../types';
import { DarkModeToggle } from '../dark-mode-toggle';
import { FlattenSideBarLinks } from '../layout';

import { HeaderSelectMobile } from './header-select-mobile';
import { NavItemType } from './nav-item';

const LARGE_VIEW_WIDTH = 1024;

export const HeaderMobileItem: React.FC<
  NavItemType & { currentLink: string }
> = ({ groupNavLink, menu, currentLink }) => {
  const groupNavURL = groupNavLink[0].linkType[0].source;
  const { openInNewTab, linkName: groupName } = groupNavLink[0];

  return (
    <li className="mb-4">
      {menu && menu.length > 0 ? (
        <>
          <strong className="px-3 pt-2 text-1.5 font-bold leading-7">
            {groupName}
          </strong>
          <ul>
            {menu.map(
              ({
                id: menuLinkId,
                linkName,
                linkType,
                openInNewTab: menuLinkOpenInNewTab,
              }) => (
                <li className="px-3 pt-3 last:pb-4" key={menuLinkId}>
                  <Link
                    className={classNames(
                      'text-md font-normal leading-extra-tight py-2 px-4 w-full block',
                      {
                        'bg-menu-highlight dark:bg-grey-800 link-active':
                          linkType[0].source === currentLink,
                      },
                    )}
                    href={linkType[0].source}
                    isOpenedInNewTab={menuLinkOpenInNewTab}
                  >
                    {linkName}
                  </Link>
                </li>
              ),
            )}
          </ul>
        </>
      ) : (
        <Link
          className={classNames(
            'px-3 py-2 text-1.5 font-bold leading-7 w-full block',
            {
              'bg-grey-40 dark:bg-grey-800 link-active':
                groupNavURL === currentLink,
            },
          )}
          href={groupNavURL}
          isOpenedInNewTab={openInNewTab}
        >
          {groupName}
        </Link>
      )}
    </li>
  );
};

export type HeaderMobileType = {
  headerLinks: NavItemType[];
  sideBarLinks?: FlattenSideBarLinks;
  versions: SuiteVersionRecord[];
  onVersionChange: (x: any) => void;
  versionSelected: SuiteVersionRecord;
  showVersions: boolean;
};

export const HeaderMobile: React.FC<HeaderMobileType> = ({
  headerLinks,
  sideBarLinks,
  onVersionChange,
  versionSelected,
  versions,
  showVersions,
}) => {
  const router = useRouter();
  const { currentLink } = useCurrentLink(router);
  const [isMenuOpen, toggleMenu] = useState(false);
  const menuClassName = classNames(
    'absolute inset-0 w-full h-screen top-20 bg-grey-20 dark:bg-blue-900 text-blue-900 dark:text-white border-t border-t-grey-100 dark:border-t-grey-700 header-mobile-nav',
    {
      hidden: !isMenuOpen,
    },
  );

  const closeMenu = (): void | null => {
    // Close mobile menu to avoid styling clashes when we hit Large viewport
    const shouldCloseMenu = window.innerWidth >= LARGE_VIEW_WIDTH;

    if (!shouldCloseMenu) {
      return null;
    }

    return toggleMenu(false);
  };

  // clean up hash from current link and check if current link belongs to an sidebar api suite
  const filteredCurrentLink = (
    link: string,
    flattenSideBarLinks?: FlattenSideBarLinks,
  ): string => {
    // remove hash
    const linkWithoutHash = link.includes('#') ? link.split('#')[0] : link;

    // if current page does not belong to an API suite then return the link
    if (!flattenSideBarLinks) return linkWithoutHash;

    // else checking if the link belongs to the current sidebar suite that coming with the page
    const isBelongToSidebarSuite =
      flattenSideBarLinks.sidebarLinks.findIndex(
        (sidebarLink) => sidebarLink === linkWithoutHash,
      ) > -1;

    // return the sidebar suite link in global navigation if the currentlink belong to the sidebar suite
    // else return the link
    return isBelongToSidebarSuite
      ? flattenSideBarLinks.sidebarSuiteLink
      : linkWithoutHash;
  };

  useEffect(() => {
    window.addEventListener('resize', closeMenu);

    return () => {
      window.removeEventListener('resize', closeMenu);
    };
  }, []);

  /**
   * lock body scroll when menu is open in mobile view
   */
  useHandleLockBodyScroll({
    isBodyScrollLocked: isMenuOpen,
    overrideHandling: () => {
      const sideBar = document.getElementById('sideBar');
      if (sideBar && !sideBar.classList.contains('hidden')) return;
      if (isMenuOpen) {
        document.body.classList.remove('overflow-auto');
        document.body.classList.add('overflow-hidden');

        return;
      }
      document.body.classList.remove('overflow-hidden');
      document.body.classList.add('overflow-auto');
    },
  });

  /**
   * minimise mobile menu if select a page
   */
  useHandleWhenRouterChangeComplete(router, () => {
    toggleMenu(false);
  });

  const renderNavButton = () => (
    <button
      aria-label={`${!isMenuOpen ? 'Show' : 'Hide'} mobile navigation`}
      className="relative m-auto w-7 h-5 button-mobile-menu"
      onClick={() => toggleMenu(!isMenuOpen)}
      type="button"
    >
      <div
        className={`absolute w-7 h-0.5 ${
          isMenuOpen
            ? 'bottom-0 my-auto bg-blue-900 dark:bg-white'
            : 'bg-blue-900 dark:bg-white'
        }`}
        style={{
          transform: isMenuOpen ? 'rotate(45deg)' : 'rotate(0deg)',
          top: isMenuOpen ? 0 : '25%',
        }}
      />
      <div
        className={`absolute w-7 h-0.5  ${
          isMenuOpen
            ? 'top-0 my-auto bg-blue-900 dark:bg-white'
            : 'bg-blue-900 dark:bg-white'
        }`}
        style={{
          transform: isMenuOpen ? 'rotate(-45deg)' : 'rotate(0deg)',
          bottom: isMenuOpen ? 0 : '25%',
        }}
      />
    </button>
  );

  const renderNavPanel = () => (
    <nav className={menuClassName}>
      <h2 className="sr-only">Global navigation</h2>
      <ul className="overflow-y-auto px-4 pt-9 pb-40 w-full h-full">
        {headerLinks &&
          headerLinks.length > 0 &&
          headerLinks.map((headerLink) => (
            <HeaderMobileItem
              currentLink={filteredCurrentLink(currentLink, sideBarLinks)}
              key={headerLink.id}
              {...headerLink}
            />
          ))}
        {showVersions && (
          <li className="mb-4">
            <HeaderSelectMobile
              onChange={onVersionChange}
              options={versions}
              value={versionSelected.value}
            />
          </li>
        )}

        <li className="px-3 pt-5 pb-16">
          <Link
            className="py-4 w-full text-lg tracking-wider leading-8 text-green-400 bg-green-900 hover:bg-green-900/80 cursor-pointer btn"
            href="/support"
          >
            Support
          </Link>
        </li>
      </ul>
    </nav>
  );

  return (
    <div className="flex justify-center align-middle lg:hidden">
      <div className="pr-4">
        <DarkModeToggle />
      </div>
      {renderNavButton()}
      {renderNavPanel()}
    </div>
  );
};
