import classNames from 'classnames';
import { useRouter } from 'next/router';
import { useState } from 'react';

import MinusIcon from 'assets/images/minus-icon.svg';
import PlusIcon from 'assets/images/plus-icon.svg';
import {
  useCurrentLink,
  useHandleLockBodyScroll,
  useHandleWhenRouterChangeComplete,
} from 'lib/customHooks';
import { SideBarSuite } from 'types/sidebar-types';

import { SideBarAccordion } from './sidebar-accordion';
import { SideBarLinkContainer } from './sidebar-linkcontainer';

export const SideBar: React.FC<{
  sideBarItems: SideBarSuite;
}> = ({
  sideBarItems: { sidebarTitle, pageLinks, accordions, externalLinks },
}) => {
  // do not render if there is no content
  if (
    pageLinks?.length === 0 &&
    accordions?.length === 0 &&
    externalLinks?.length === 0
  ) {
    return <></>;
  }

  const router = useRouter();
  const { currentPath } = useCurrentLink(router);
  const [showMobileSidebar, setShowMobileSideBar] = useState(false);

  /**
   * lock body scroll when sidebar is open in mobile view
   */
  useHandleLockBodyScroll({
    isBodyScrollLocked: showMobileSidebar,
  });

  /**
   * minimise mobile sidebar if select a page
   */
  useHandleWhenRouterChangeComplete(router, () => {
    setShowMobileSideBar(false);
  });

  return (
    <aside className="container fixed inset-x-0 z-1 px-6 mx-auto bg-white dark:bg-blue-900 border-grey-300 dark:border-grey-700 lg:overflow-y-auto lg:sticky lg:inset-x-auto lg:top-0 lg:z-auto lg:col-start-2 lg:col-end-4 lg:px-0 lg:pt-40 lg:pb-8 lg:-mr-8 lg:-ml-12 lg:w-auto lg:h-screen lg:border-r lg:opacity-100 lg:transition-opacity">
      <div className="fixed inset-x-0 pt-20 bg-white dark:bg-blue-900 border-b border-grey-100 dark:border-grey-700 lg:hidden">
        <div className="container px-m mx-auto">
          <button
            className="py-xxs px-3 my-3 dark:text-grey-20 bg-grey-20 dark:bg-sidebar-button-dark rounded-md border border-grey-200 sidebar-button-document"
            onClick={() => {
              setShowMobileSideBar(!showMobileSidebar);
            }}
          >
            <span className="flex items-center">
              Documentation
              {showMobileSidebar ? (
                <MinusIcon className="ml-1 text-grey-600 dark:text-grey-200" />
              ) : (
                <PlusIcon className="ml-1 text-grey-600 dark:text-grey-200" />
              )}
            </span>
          </button>
        </div>
      </div>
      <nav
        aria-labelledby="sidebar-title"
        className={classNames(
          'py-40 h-screen overflow-y-auto -mx-m lg:mx-0 lg:h-auto lg:py-0 lg lg:block',
          {
            hidden: !showMobileSidebar,
            'block ': showMobileSidebar,
          },
        )}
        id="sideBar"
      >
        <strong
          className="block mx-m mb-3.5 text-lg font-normal leading-7 lg:pr-4 lg:pl-2 lg:mx-0 dark:text-gray-40"
          id="sidebar-title"
        >
          {sidebarTitle}
        </strong>
        <strong className="block mx-m mt-12 mb-3.5 font-normal leading-normal dark:text-grey-40 lg:pl-2 lg:mx-0">
          Overview
        </strong>
        <ul className="mx-s last:mt-0 lg:grid lg:grid-cols-4 lg:gap-0 lg:mx-0">
          {pageLinks.map(({ id, linkUrl, linkName }, index) => (
            <SideBarLinkContainer
              className={classNames(
                'py-1 px-xxs text-sm leading-tight text-grey-700 dark:text-grey-40 hover:text-blue-900 hover:bg-grey-40 dark:hover:bg-grey-700 hover:rounded-sm lg:px-2 lg:col-span-3',
                {
                  'mb-2.5': index < pageLinks.length - 1,
                  'mb-0': index === pageLinks.length - 1, // last
                },
              )}
              isActive={currentPath === linkUrl}
              key={id}
              title={linkName}
              url={linkUrl}
            />
          ))}
          {accordions.map(
            ({ id, accordionGroupTitle, accordionGroupLinks }, index) => (
              <SideBarAccordion
                className={classNames('lg:col-span-3', {
                  'mt-12': index === 0, // first
                  'mb-6': index < accordions.length - 1,
                  'mb-0': index === accordions.length - 1, // last
                })}
                currentLink={currentPath}
                isAlwaysExpanded={accordions.length === 1} // expand when only one accordion group exists
                key={id}
                links={accordionGroupLinks}
                title={accordionGroupTitle}
              />
            ),
          )}
          {externalLinks.map(
            ({ id, linkUrl, linkName, openInNewTab }, index) => (
              <SideBarLinkContainer
                className={classNames(
                  'text-base leading-normal text-external-link dark:text-external-link lg:pl-2 lg:col-span-3',
                  {
                    'mt-12': index === 0, // first
                    'mb-3.5': index < pageLinks.length - 1,
                    'mb-0': index === pageLinks.length - 1, // last
                  },
                )}
                key={id}
                openInNewTab={openInNewTab}
                title={linkName}
                url={linkUrl}
              />
            ),
          )}
        </ul>
      </nav>
    </aside>
  );
};
