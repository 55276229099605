import { Listbox, Transition } from '@headlessui/react';
import { Fragment, ReactElement } from 'react';

export type OptionType = {
  value: string;
  label: string;
  labelExtra?: string;
};

export type SelectType = {
  // use primitive value for Select so that the active state
  // isn't being determined by a reference to an object
  value: string | number;
  buttonClasses?: string;
  menuClasses?: string;
  placeholder?: string;
  optionClasses?: string;
  optionActiveClasses?: string;
  onChange: (option: any) => void;
  options: OptionType[];
  renderButton?: () => ReactElement;
};

export const Select: React.FC<SelectType> = ({
  onChange,
  options,
  placeholder = '',
  value,
  buttonClasses,
  menuClasses,
  optionClasses,
  optionActiveClasses,
  renderButton,
}) => {
  // need to look up the current option now that the value is a primitive
  const current = options.find((o) => o.value === value);

  return (
    <div className="relative h-full">
      <Listbox onChange={onChange} value={value}>
        <>
          <Listbox.Button className={buttonClasses}>
            {renderButton ? (
              renderButton()
            ) : (
              <span>{current?.label ?? placeholder}</span>
            )}
          </Listbox.Button>
          <Transition
            as={Fragment}
            enter="transition duration-100 ease-in"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="transition duration-75 ease-out"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Listbox.Options className={menuClasses}>
              {options?.map(
                (option): JSX.Element => (
                  <Listbox.Option
                    as={Fragment}
                    key={option.value}
                    value={option.value}
                  >
                    {({ active, selected }) => (
                      <li
                        className={`${
                          active || selected ? optionActiveClasses : ''
                        } ${optionClasses}`}
                      >
                        {option.label}
                        {option.labelExtra}
                      </li>
                    )}
                  </Listbox.Option>
                ),
              )}
            </Listbox.Options>
          </Transition>
        </>
      </Listbox>
    </div>
  );
};
