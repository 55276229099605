import { useRouter } from 'next/router';
import { FC, ReactElement, useMemo } from 'react';

import { SideBarSuite } from 'types/sidebar-types';

import { BackButton, BackButtonProps } from '../back-button';
import { Footer, FooterType } from '../footer';
import { Header, HeaderType } from '../header';
import { SideBar } from '../sidebar';
import { TOCContainer } from '../table-of-content';
import { VersionAlert } from '../version-alert';

type LayoutType = {
  children: ReactElement;
  sideBar?: SideBarSuite | null;
  hasPadding?: boolean;
  onBack?: () => void;
  showTableOfContent?: boolean;
  showBackButton?: boolean;
  header: HeaderType;
  footer: FooterType;
};

export type FlattenSideBarLinks = {
  sidebarSuiteLink: string;
  sidebarLinks: string[];
};

export const Layout: FC<LayoutType> = ({
  children,
  sideBar,
  showBackButton,
  hasPadding = true,
  onBack,
  showTableOfContent,
  header,
  footer,
}) => {
  const router = useRouter();
  const backBtnProps: BackButtonProps = {
    isShown: showBackButton,
  };
  if ((router.pathname === '/' && !showBackButton) || !showBackButton) {
    backBtnProps.isShown = false;
  }
  if (onBack) backBtnProps.onBack = onBack;
  // flatten sidebar suite url into a string array
  const flattenSideBarLinks: FlattenSideBarLinks | undefined = useMemo(() => {
    if (!sideBar) return undefined;

    const result: string[] = [];

    sideBar.pageLinks.forEach((pageLink) => result.push(pageLink.linkUrl));
    sideBar.accordions.forEach((accordion) =>
      accordion.accordionGroupLinks.forEach((accordionGroupLink) =>
        result.push(accordionGroupLink.linkUrl),
      ),
    );

    return {
      sidebarSuiteLink: sideBar.sidebarSuiteLink,
      sidebarLinks: result,
    };
  }, [sideBar]);

  const renderFullWidthContainer = () => {
    return (
      //  TODO update this later when grid design is final and understood
      <div className="flex flex-row min-h-screen font-brown">
        <div className="container px-m mx-auto lg:grid lg:grid-cols-14 lg:gap-8 lg:px-0">
          {sideBar && <SideBar sideBarItems={sideBar} />}
          <div
            className={`${
              sideBar ? 'col-start-5' : 'col-start-4'
            } col-end-12 pt-40`}
          >
            <BackButton {...backBtnProps} />
            {children}
          </div>
          {showTableOfContent && <TOCContainer childrenNode={children} />}
        </div>
      </div>
    );
  };

  return (
    <div className="relative">
      <Header {...header} sideBarLinks={flattenSideBarLinks} />
      <VersionAlert hasSidebar={!!sideBar} />
      <main className="dark:text-white dark:bg-blue-900">
        {hasPadding ? renderFullWidthContainer() : children}
      </main>
      <Footer {...footer} />
    </div>
  );
};
