import { Menu, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';

import { Link } from 'components/common/link';

import ChevronDown from '../../../assets/images/chevron-down.inline.svg';

export type NavLinkType = {
  source: string;
  _modelApiKey: string;
};

export type NavLinkRecord = {
  linkName: string;
  linkType: Array<NavLinkType>;
  openInNewTab: boolean;
  id: string;
};

export type NavItemType = {
  groupNavLink: Array<NavLinkRecord>;
  menu?: Array<NavLinkRecord>;
  id: string;
};

export const NavItem: React.FC<NavItemType> = ({ groupNavLink, menu }) => {
  const titleLink = groupNavLink[0];
  const { asPath: currentRoute } = useRouter();
  const activeBtnClass = 'border-b-green-400';
  const [borderColor, setBorderColor] = useState('border-transparent');

  const buttonRef = useRef<HTMLButtonElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const timeoutDuration = 200;
  let timeout: NodeJS.Timeout;

  const openMenu = () => buttonRef?.current?.click();

  const closeMenu = () => {
    dropdownRef?.current?.dispatchEvent(
      new KeyboardEvent('keydown', {
        key: 'Escape',
        bubbles: true,
        cancelable: true,
      }),
    );
  };

  const onMouseEnter = (closed?: boolean) => {
    clearTimeout(timeout);
    if (closed) {
      openMenu();
      buttonRef.current?.focus();
    }
  };

  const onMouseLeave = (open: boolean) => {
    if (open) timeout = setTimeout(() => closeMenu(), timeoutDuration);
  };

  useEffect(() => {
    setBorderColor(
      currentRoute !== '/' &&
      menu?.map((item: any) => {
        return item?.linkType[0]?.source
      }).some((item: any) => {
        return currentRoute.includes(item)
      })
        ? activeBtnClass
        : 'border-transparent',
    );
  }, [currentRoute, titleLink.linkType[0].source]);

  const linkClassName = classNames(
    'flex h-full items-center border-b-5 mr-2',
    borderColor,
    `hover:border-b-green-400`,
  );

  if (menu?.length) {
    return (
      <Menu as="li" className="relative mr-2 h-full">
        {({ open }) => (
          <>
            <div
              className={classNames(
                'flex items-center h-full border-b-5',
                open ? activeBtnClass : borderColor,
              )}
              onMouseEnter={() => onMouseEnter(!open)}
              onMouseLeave={() => onMouseLeave(open)}
              role="button"
              tabIndex={-1}
            >
              <Menu.Button
                className="flex items-center px-3 pt-1 h-full rounded-sm outline-none focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-800 focus-visible:ring-opacity-75"
                onClick={openMenu}
                ref={buttonRef}
              >
                <div className="flex items-center h-full">
                  <span className="flex items-center h-full">
                    {titleLink.linkName}
                  </span>
                  <ChevronDown
                    className={classNames(
                      'ml-1 text-blue-900 dark:text-white fill-current',
                      open ? 'rotate-180' : '',
                      'transition-all duration-200',
                    )}
                  />
                </div>
              </Menu.Button>
            </div>

            <Transition
              enter="transition duration-100 ease-in"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="transition duration-75 ease-out"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
              show={open}
            >
              <div className="pt-3">
                <Menu.Items
                  className="absolute z-50 py-3 w-56 text-base list-none bg-white dark:bg-dark-grey rounded border border-transparent dark:border-grey-700 focus:outline-none shadow-extra-small"
                  onMouseEnter={() => onMouseEnter()}
                  onMouseLeave={() => onMouseLeave(open)}
                  ref={dropdownRef}
                  static
                >
                  {menu?.map(({ linkName, openInNewTab, linkType, id }) => {
                    return (
                      <Menu.Item key={id}>
                        {({ active }) => (
                          <Link
                            className={classNames(
                              `flex items-center py-xs px-m text-grey-700 dark:text-white dark:hover:text-grey-40 dark:hover:bg-grey-700`,
                              {
                                'bg-grey-40 text-blue-900 dark:text-grey-40 dark:bg-grey-700':
                                  active,
                                'text-grey-700': !active,
                              },
                            )}
                            href={linkType[0].source}
                            isOpenedInNewTab={openInNewTab}
                          >
                            {linkName}
                          </Link>
                        )}
                      </Menu.Item>
                    );
                  })}
                </Menu.Items>
              </div>
            </Transition>
          </>
        )}
      </Menu>
    );
  }

  return (
    <li className={linkClassName}>
      <Link
        className="flex items-center px-3 pt-1 w-full h-full"
        href={titleLink.linkType[0].source}
        isOpenedInNewTab={titleLink.openInNewTab}
      >
        <span>{titleLink.linkName}</span>
      </Link>
    </li>
  );
};
