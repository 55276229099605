import { useRouter } from 'next/router';

import { SuiteVersionRecord } from '../../../types';
import { FlattenSideBarLinks } from '../layout';
import { useVersionSelect } from '../version-select/version-select.provider';
import { getVersionPath } from '../version-select/versionUtils';

import { HeaderDesktop } from './header-desktop';
import { HeaderMobile } from './header-mobile';
import { NavItemType } from './nav-item';

export type NavType = {
  headerLinks: Array<NavItemType>;
};

export const Nav: React.FC<
  NavType & {
    sideBarLinks?: FlattenSideBarLinks;
  }
> = ({ headerLinks, sideBarLinks }) => {
  const router = useRouter();
  const { asPath: path } = router;
  const {
    versionState: { suite, showVersions, currentV, latestVersionNum },
  } = useVersionSelect();

  const suiteVersions = suite
    ? suite[currentV.suite.tagId].map((v: SuiteVersionRecord) => ({
        ...v,
        // v.label currently gets used to construct URLS so don't want to pollute with "(Latest)"
        labelExtra: latestVersionNum === Number(v.value) ? ' (Latest)' : '',
      }))
    : [];

  const handleVersionChange = (value: string) => {
    const nextVersion = suiteVersions.find(
      (version: SuiteVersionRecord) => version.value === value,
    );

    const newURL = getVersionPath(path, currentV, nextVersion);
    router.push(newURL);
  };

  const props = {
    headerLinks,
    suite,
    versions: suiteVersions,
    versionSelected: currentV,
    onVersionChange: handleVersionChange,
    showVersions,
  };

  return (
    <>
      <HeaderDesktop {...props} />
      <HeaderMobile {...props} sideBarLinks={sideBarLinks} />
    </>
  );
};
