import ModeToggleIcon from 'assets/images/mode-toggle.svg';

import { useDarkMode } from './dark-mode-toggle.provider';

export const DarkModeToggle = () => {
  const { toggleTheme } = useDarkMode();

  return (
    <button
      aria-hidden
      className="p-2 bg-grey-200/20 rounded-full ring-1 ring-grey-400 hover:ring-grey-600 ring-opacity-20"
      onClick={() => toggleTheme()}
      tabIndex={-1}
      title="Toggle theme"
    >
      <ModeToggleIcon className="text-blue-900 dark:text-white fill-current" />
    </button>
  );
};
