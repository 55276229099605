import classNames from 'classnames';
import { useRouter } from 'next/router';

import ChevronIcon from 'public/chevron-icon.svg';

import { AnchorClickEvent, ButtonClickEvent } from '../button';

export type BackButtonProps = {
  isShown?: boolean;
  onBack?: () => void;
  onClick?: AnchorClickEvent | ButtonClickEvent;
  className?: string;
};

const BASE_CLASSES = [
  'active:bg-blue-900',
  'active:text-green-400',
  'align-center',
  'bg-transparent',
  'border',
  'border-green-400',
  'border-opacity-100',
  'dark:hover:bg-white/[.2]',
  'dark:hover:text-white',
  'dark:text-grey-40',
  'duration-300',
  'font-normal',
  'h-8',
  'hover:bg-green-100',
  'hover:text-blue-900',
  'inline-flex',
  'items-center',
  'justify-center',
  'leading-extra-tight',
  'outline-none',
  'px-3',
  'py-xxs',
  'rounded-full',
  'text-blue-900',
  'text-sm',
  'tracking-normal',
  'transition-colors',
];

export const BackButton: React.FC<BackButtonProps> = ({
  isShown = true,
  onBack,
  className,
}) => {
  const router = useRouter();

  if (!isShown) return null;
  const handleBack = onBack || router.back;
  const classes = classNames(className, BASE_CLASSES);

  return (
    <button className={classes} onClick={handleBack} type="button">
      <span className="mr-xxs">
        <ChevronIcon height={16} width={16} />
      </span>
      Back
    </button>
  );
};
