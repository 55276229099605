import ChevronDown from '../../../assets/images/chevron-down.inline.svg';
import { Select, SelectType } from '../select';

export const HeaderSelectDesktop: React.FC<SelectType> = ({
  onChange,
  value,
  options,
}) => {
  const current = options.find((o) => o.value === value);

  return (
    <Select
      buttonClasses="relative pr-l pl-3.5 min-w-[5rem] h-full text-sm leading-4 text-left bg-transparent rounded-full border border-green-400 outline-none focus-visible:ring-2 focus-visible:ring-blue-800 focus-visible:ring-opacity-75"
      menuClasses="absolute z-50 p-1 mt-xs w-full min-w-[7.5rem] max-h-60 text-base list-none bg-white dark:bg-dark-grey rounded-[5px] border border-transparent dark:border-grey-700 focus:outline-none shadow-extra-small"
      onChange={onChange}
      optionActiveClasses="text-grey-800 dark:text-white bg-grey-40 dark:bg-grey-700"
      optionClasses="flex text-sm items-center capitalize rounded py-xs px-3 text-grey-700 dark:text-white hover:text-blue-900 dark:hover:text-grey-40 hover:bg-grey-40 dark:hover:bg-grey-700 cursor-pointer"
      options={options}
      renderButton={() => {
        return (
          <>
            <span className="block uppercase truncate">
              {current?.label ?? ''}
            </span>
            <span className="flex absolute inset-y-0 right-0 items-center pr-2 text-blue-900 pointer-events-none">
              <ChevronDown
                aria-hidden="true"
                className="ml-1 text-blue-900 dark:text-white fill-current"
                height={16}
                width={16}
              />
            </span>
          </>
        );
      }}
      value={value}
    />
  );
};
