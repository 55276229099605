import { BAKKT_SUPPORT_URL } from 'lib/constants';
import BakktLogo from 'public/bakkt-logo.inline.svg';

import { Button } from '../button';
import { Container } from '../container';
import { NavItemType, NavLinkRecord } from '../header';
import { Link } from '../link';

export type FooterType = {
  footerLinks: Array<NavItemType>;
};

const FooterLink: React.FC<NavLinkRecord> = ({
  linkName,
  openInNewTab,
  linkType,
}) => (
  <div className="mb-xs">
    <Link
      attrs={{ 'aria-label': linkName }}
      className="text-sm leading-tight hover:underline focus:underline"
      href={linkType[0].source}
      isOpenedInNewTab={openInNewTab}
    >
      {linkName}
    </Link>
  </div>
);

export const Footer: React.FC<FooterType> = ({ footerLinks }) => (
  <footer className="font-brown text-white bg-blue-900">
    <Container>
      <div className="container py-xl">
        <div className="grid grid-cols-12 gap-m">
          <div className="flex col-span-12 lg:col-span-2">
            <Link
              attrs={{
                'aria-label': 'Bakkt, Back to homepage',
              }}
              className="block mx-auto w-24 hover:text-green-400 transition-colors lg:mx-0"
              href="/"
            >
              <BakktLogo className="w-full h-auto" />
            </Link>
          </div>
          <div className="flex flex-wrap col-span-12 lg:col-span-8">
            {footerLinks?.map(({ groupNavLink, menu, id }) => {
              const titleLink = groupNavLink[0];

              return (
                <div
                  className="pr-m mb-base w-full sm:w-1/2 md:w-1/4 lg:max-w-3xs"
                  key={id}
                >
                  <strong className="block mb-xs text-lg font-normal leading-extra-tight">
                    {titleLink.linkName}
                  </strong>
                  {menu?.map((menuItem) => {
                    return <FooterLink {...menuItem} key={menuItem.id} />;
                  })}
                </div>
              );
            })}
          </div>
          <div className="flex col-span-12 justify-center lg:col-span-2 lg:justify-end">
            <div>
              <Button
                className="border-0"
                href={BAKKT_SUPPORT_URL}
                isLink
                type="button"
                variant="secondary"
              >
                Support
              </Button>
            </div>
          </div>
        </div>
        <div className="flex mt-l w-full">
          <div>
            <small className="text-xs text-grey-100">
              © {new Date().getFullYear()} Bakkt
            </small>
          </div>
        </div>
      </div>
    </Container>
  </footer>
);
