import ChevronRight from '../../../assets/images/chevron-right.inline.svg';
import { Select, SelectType } from '../select';

export const HeaderSelectMobile: React.FC<SelectType> = ({
  onChange,
  value,
  options,
}) => {
  return (
    <Select
      buttonClasses="relative mr-2 w-full h-full outline-none"
      menuClasses="py-3 text-base list-none focus:outline-none"
      onChange={onChange}
      optionActiveClasses="bg-menu-highlight dark:bg-grey-800 link-active"
      optionClasses="text-md font-normal capitalize leading-extra-tight py-2 px-4 w-full block cursor-pointer"
      options={options}
      renderButton={() => {
        return (
          <div className="flex justify-between items-center py-2 px-3 w-full h-full text-md font-normal leading-extra-tight text-left">
            <strong className="text-1.5 font-bold leading-7">Versions</strong>
            <span className="flex absolute inset-y-0 right-0 items-center pr-2 text-blue-900 pointer-events-none">
              <span className="flex items-center text-blue-900 pointer-events-none">
                <ChevronRight
                  aria-hidden="true"
                  className="ml-1 text-blue-900 dark:text-white fill-current"
                  height={19}
                  width={11}
                />
              </span>
            </span>
          </div>
        );
      }}
      value={value}
    />
  );
};
